import Image1 from "public/assets/avatar/image1.png";
import Image2 from "public/assets/avatar/image2.png";

export const testimonials = [
  {
    id: 1,
    image: Image2,
    name: "Orang tua Almira",
    relation: "SD Kelas 5",
    title: "Dulu Almira gak bisa banget Matematika, sekarang jadi bisa!",
    videoUrl: "https://www.youtube.com/watch?v=yd-lZ3A_1Is&feature=youtu.be"
  },
  {
    id: 2,
    image: Image1,
    name: "Aulia",
    relation: "SMA Kelas 10",
    location: "",
    title:
      "Sebelum ikut CoLearn kaya takut-takut gitu jawab di kelas, sekarang jadi  berani",
    videoUrl: null
  }
];
