import { rem } from "polished";
import styled from "styled-components";
import { breakpoints } from "styles";

export const Wrapper = styled.section`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const WhatIsAvailableContainer = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${breakpoints.laptop} {
    padding: ${rem(80)};
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 16px;
    gap: 10px;
    padding: 0px;
  }
`;

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  margin: 0;
  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
    line-height: 42px;
    margin: 0 0 40px;
  }
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
    text-align: center;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 15px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  position: relative;
  min-height: 168px;
  min-width: 300px;
  @media ${breakpoints.tablet} {
    min-width: 380px;
  }
  @media ${breakpoints.mobile} {
    margin: 0px;
    min-height: 0px;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 40px;
  @media ${breakpoints.tablet} {
    left: 45px;
  }
  @media ${breakpoints.mobile} {
    left: 10px;
    top: 50px;
  }
`;

export const Grade = styled.div<{ grade: string }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: ${({ theme, grade }) =>
    grade === "Matematika" ? theme.fontSizes["2xl"] : theme.fontSizes["lg"]};

  @media ${breakpoints.mobile} {
    padding-left: 20px;
  }
`;

export const GradeSub = styled.div<{ gradeSub: string }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: ${({ theme, gradeSub }) =>
    gradeSub === "4 SD - 12 SMA"
      ? theme.fontSizes["2xl"]
      : theme.fontSizes["lg"]};
  @media ${breakpoints.mobile} {
    padding-left: 20px;
  }
`;

export const ImageContainer = styled.div`
  padding: 10px;
  border-radius: 10px;
  img {
    border-radius: 10px;
  }
`;
