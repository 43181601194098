import React from "react";
import {
  Wrapper,
  WhatIsAvailableContainer,
  Heading,
  CardContainer,
  TextContainer,
  Card,
  Grade,
  ImageContainer,
  GradeSub
} from "./what-is-available.styles";
import UntukImg from "public/assets/home/available-classes/untuk-kelas.png";
import KurikulumImg from "public/assets/home/available-classes/Kurikulum Merdeka.png";
import MatematicaImg from "public/assets/home/available-classes/Mathematika.png";
import Image from "next/legacy/image";
import { useMediaQuery } from "hooks/use-media-query/use-media-query";
import UntukImgMobile from "public/assets/home/available-classes/untuk-kelas-mobile.png";
import KurikulumImgMobile from "public/assets/home/available-classes/kurikulum-merdeka-mobile.png";
import MatematicaImgMobile from "public/assets/home/available-classes/mathematika-mobile.png";
const ParentComponent = ({ children }: { children: React.ReactNode }) => {
  return <CardContainer>{children}</CardContainer>;
};

export const WhatIsAvailableSection: React.FC = () => {
  const isMobile = useMediaQuery("mobile");
  return (
    <Wrapper>
      <WhatIsAvailableContainer>
        <Heading>Di Bimbel CoLearn, tersedia:</Heading>

        {isMobile ? (
          <ParentComponent>
            {availableGradesMobile.map(({ img, grade, gradeSub }) => (
              <Card key={grade}>
                <ImageContainer>
                  <Image src={img} alt={grade + gradeSub} />
                </ImageContainer>

                <TextContainer>
                  <Grade grade={grade}>{grade}</Grade>
                  <GradeSub gradeSub={gradeSub}>{gradeSub}</GradeSub>
                </TextContainer>
              </Card>
            ))}
          </ParentComponent>
        ) : (
          <ParentComponent>
            {availableGrades.map(({ img, grade, gradeSub }) => (
              <Card key={grade}>
                <ImageContainer>
                  <Image
                    src={img}
                    alt={grade + gradeSub}
                    layout="fill"
                    objectFit="cover"
                  />
                </ImageContainer>
                <TextContainer>
                  <Grade grade={grade}>{grade}</Grade>
                  <GradeSub gradeSub={gradeSub}>{gradeSub}</GradeSub>
                </TextContainer>
              </Card>
            ))}
          </ParentComponent>
        )}
      </WhatIsAvailableContainer>
    </Wrapper>
  );
};

const availableGrades = [
  {
    img: UntukImg,
    grade: "Untuk Kelas",
    gradeSub: "4 SD - 12 SMA"
  },
  {
    img: KurikulumImg,
    grade: "Kurikulum Merdeka",
    gradeSub: "Kurikulum 2013"
  },
  {
    img: MatematicaImg,
    grade: "Matematika",
    gradeSub: "Fisika, Kimia, IPA"
  }
];

const availableGradesMobile = [
  {
    img: UntukImgMobile,
    grade: "Untuk Kelas",
    gradeSub: "4 SD - 12 SMA"
  },
  {
    img: KurikulumImgMobile,
    grade: "Kurikulum Merdeka",
    gradeSub: "Kurikulum 2013"
  },
  {
    img: MatematicaImgMobile,
    grade: "Matematika",
    gradeSub: "Fisika, Kimia, IPA"
  }
];
